import {Modal, ModalContent, ModalHeader, ModalBody, Button, Input} from "@nextui-org/react";
import {useContext, useEffect, useState} from "react";
import {CloseIcon} from "../../assets/icons/CloseIcon";
import {useNavigate} from "react-router";
import axios from "axios";
import AuthContext from "../../context/authContext";

export const EmployersTopNav = () => {
    const history = useNavigate();
    const {setFilterCities, setFilterCategories} = useContext(AuthContext);
    const [cityModal, setCityModal] = useState(false);
    const [categoryModal, setCategoryModal] = useState(false);

    const [cities, setCities] = useState([])

    const [categories, setCategories] = useState([])

    useEffect(() => {
        axios.get("https://backend.sorpa.com/properties/admin/list/").then(
            res => {
                setCities(res.data.cities);
                setCategories(res.data.sub_categories);
                setFilterCategories(res.data.sub_categories);
                setFilterCities(res.data.cities);
            }
        ).catch(
            err => {
                console.log(err.response?.data?.message);
            }
        )
    }, []);

    const [cityError, setCityError] = useState("");
    const [categoryError, setCategoryError] = useState("");

    const [newCity, setNewCity] = useState(
        {
            country_name: "",
            city_name: "",
        }
    )

    const [newCategory, setNewCategory] = useState(
        {
            category_name: "",
            sub_category_name: "",
        }
    )

    const submitCity = () => {
        axios.post("https://backend.sorpa.com/properties/cities/create/", {...newCity}).then(
            res => {
                setCities(res.data);
                setCityModal(false);
                setFilterCities(res.data);
            }
        ).catch(
            err => {
                setCityError(err.response?.data?.message)
            }
        )
    }

    const submitCategory = () => {
        axios.post("https://backend.sorpa.com/properties/categories/create/", {...newCategory}).then(
            res => {
                setCategories(res.data);
                setCategoryModal(false);
                setFilterCategories(res.data);
            }
        ).catch(
            err => {
                setCategoryError(err.response?.data?.message)
            }
        )
    }

    return (
        <div style={{height: 72, display: "flex", alignItems: "center", boxSizing: "border-box", padding: 16, gap: 16}}>
            {/*кнопки*/}
            <Button
                onClick={() => {
                    history("/employers/add")
                }}
                style={{
                    width: 240,
                    border: "1px solid #BDFF00",
                    background: "none",
                    color: "#BDFF00",
                    fontWeight: 600,
                    borderRadius: 16
                }}
            >
                <p>Добавить Предприятие</p>
            </Button>
            {
                localStorage.getItem("role") !== "Внедренец" &&
                <>
                    <Button
                        onClick={() => {
                            setCityModal(true)
                        }}
                        style={{
                            width: 240,
                            border: "1px solid #BDFF00",
                            background: "none",
                            color: "#BDFF00",
                            fontWeight: 600,
                            borderRadius: 16
                        }}
                    >
                        <p>Добавить Город</p>
                    </Button>
                    <Button
                        onClick={() => {
                            setCategoryModal(true)
                        }}
                        style={{
                            width: 240,
                            border: "1px solid #BDFF00",
                            background: "none",
                            color: "#BDFF00",
                            fontWeight: 600,
                            borderRadius: 16
                        }}
                    >
                        <p>Добавить Категорию</p>
                    </Button>
                </>
            }
            {/*модалки*/}
            {/*модалка городов*/}
            <Modal isOpen={cityModal} style={{background: "#121212"}} hideCloseButton={true}>
                <ModalContent>
                    <ModalHeader style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        paddingBottom: 0,
                        paddingRight: 16
                    }}>
                        <h2 style={{color: "white"}}>Добавление города</h2>
                        <Button onClick={() => {
                            setCityModal(false)
                        }} isIconOnly={true} style={{background: "none", padding: 0}}><CloseIcon/></Button>
                    </ModalHeader>
                    <ModalBody>
                        {
                            cityError && <p style={{color: "red"}}>{cityError}</p>
                        }
                        <Input
                            type={"email"}
                            label="Страна"
                            size={"sm"}
                            radius="lg"
                            style={{color: "white"}}
                            className={"input-text-color"}
                            classNames={{
                                label: "input-text-color",
                                input: "text-white/90",
                                innerWrapper: "input-text-color",
                                inputWrapper: "input-main",
                            }}
                            onChange={(event) => {
                                setNewCity({...newCity, country_name: event.target.value});
                                setCityError("");
                            }}
                        />
                        <Input
                            type={"email"}
                            label="Город"
                            size={"sm"}
                            radius="lg"
                            style={{color: "white"}}
                            className={"input-text-color"}
                            classNames={{
                                label: "input-text-color",
                                input: "text-white/90",
                                innerWrapper: "input-text-color",
                                inputWrapper: "input-main",
                            }}
                            onChange={(event) => {
                                setNewCity({...newCity, city_name: event.target.value});
                                setCityError("");
                            }}
                        />
                        <Button style={{
                            background: "#BF0",
                            color: "black",
                            fontSize: 16,
                            fontWeight: 600
                        }}
                                onClick={submitCity}
                        >
                            <p>Добавить</p>
                        </Button>
                        <div style={{
                            overflow: "auto",
                            maxHeight: 200,
                            display: "flex",
                            flexDirection: "column",
                            gap: 8,
                            paddingBottom: 12
                        }}>
                            {
                                cities.map((city, index) => {
                                    if (city.id !== 0){
                                        return (
                                            <div key={index} style={{
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "space-between"
                                            }}>
                                                <p style={{color: "white", fontWeight: "bold"}}>{city.name}</p>
                                                <Button
                                                    onClick={() => {
                                                        axios.get(`https://backend.sorpa.com/properties/cities/${city.id}/delete/`).then(res => {
                                                            setCities(res.data);
                                                            setFilterCities(res.data);
                                                        }).catch(
                                                            err => {
                                                                console.log(err.response.data.message)
                                                            }
                                                        )
                                                    }}
                                                    style={{
                                                        border: "1px solid #BDFF00",
                                                        background: "none",
                                                        color: "#BDFF00",
                                                        fontWeight: 600,
                                                        borderRadius: 12
                                                    }}
                                                >
                                                    <p>Удалить</p>
                                                </Button>
                                            </div>
                                        )
                                    }
                                })
                            }
                        </div>
                    </ModalBody>
                </ModalContent>
            </Modal>
            {/*модалка категорий*/}
            <Modal isOpen={categoryModal} style={{background: "#121212"}} hideCloseButton={true}>
                <ModalContent>
                    <ModalHeader style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        paddingBottom: 0,
                        paddingRight: 16
                    }}>
                        <h2 style={{color: "white"}}>Добавление категорий</h2>
                        <Button onClick={() => {
                            setCategoryModal(false)
                        }} isIconOnly={true} style={{background: "none", padding: 0}}><CloseIcon/></Button>
                    </ModalHeader>
                    <ModalBody>
                        {
                            categoryError && <p style={{color: "red"}}>{categoryError}</p>
                        }
                        <Input
                            type={"email"}
                            label="Категория"
                            size={"sm"}
                            radius="lg"
                            style={{color: "white"}}
                            className={"input-text-color"}
                            classNames={{
                                label: "input-text-color",
                                input: "text-white/90",
                                innerWrapper: "input-text-color",
                                inputWrapper: "input-main",
                            }}
                            onChange={(event) => {
                                setNewCategory({...newCategory, category_name: event.target.value});
                                setCategoryError("");
                            }}
                        />
                        <Input
                            type={"email"}
                            label="Подкатегория"
                            size={"sm"}
                            radius="lg"
                            style={{color: "white"}}
                            className={"input-text-color"}
                            classNames={{
                                label: "input-text-color",
                                input: "text-white/90",
                                innerWrapper: "input-text-color",
                                inputWrapper: "input-main",
                            }}
                            onChange={(event) => {
                                setNewCategory({...newCategory, sub_category_name: event.target.value});
                                setCategoryError("");
                            }}
                        />
                        <Button style={{
                            background: "#BF0",
                            color: "black",
                            fontSize: 16,
                            fontWeight: 600
                        }}
                                onClick={submitCategory}
                        >
                            <p>Добавить</p>
                        </Button>
                        <div style={{
                            overflow: "auto",
                            maxHeight: 200,
                            display: "flex",
                            flexDirection: "column",
                            gap: 8,
                            paddingBottom: 12
                        }}>
                            {
                                categories.map((category, index) => {
                                    if (category.id !== 0){
                                        return (
                                            <div key={index} style={{
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "space-between"
                                            }}>
                                                <p style={{color: "white", fontWeight: "bold"}}>{category.name}</p>
                                                <Button
                                                    onClick={() => {
                                                        axios.get(`https://backend.sorpa.com/properties/categories/${category.id}/delete/`).then(res => {
                                                            setCategories(res.data);
                                                            setFilterCategories(res.data);
                                                        }).catch(
                                                            err => {
                                                                console.log(err.response.data.message)
                                                            }
                                                        )
                                                    }}
                                                    style={{
                                                        border: "1px solid #BDFF00",
                                                        background: "none",
                                                        color: "#BDFF00",
                                                        fontWeight: 600,
                                                        borderRadius: 12
                                                    }}
                                                >
                                                    <p>Удалить</p>
                                                </Button>
                                            </div>
                                        )
                                    }
                                })
                            }
                        </div>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </div>
    )
}
