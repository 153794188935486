import {
    Button,
    Card,
    Divider,
    Input,
    Table,
    TableHeader,
    TableColumn,
    TableBody,
    TableRow,
    TableCell,
    Tooltip, Tabs, Tab, CardBody, ModalContent, ModalHeader, ModalBody, Modal, Textarea, Spinner
} from "@nextui-org/react";
import {Select, SelectItem} from "@nextui-org/react";
import {SearchIcon} from "../../assets/icons/SearchIcon";
import React, {useContext, useEffect, useRef, useState} from "react";
import AuthContext from "../../context/authContext";
import {useNavigate} from "react-router";
import {EditIcon} from "../../assets/icons/EditIcon";
import {DeleteIcon} from "../../assets/icons/DeleteIcon";
import axios from "axios";
import {Link} from "react-router-dom";
import {AddIcon} from "../../assets/icons/AddIcon";
import {CloseIcon} from "../../assets/icons/CloseIcon";
import {useAsyncList} from "@react-stately/data";
import {useInfiniteScroll} from "@nextui-org/use-infinite-scroll";
import {RefIcon} from "../../assets/icons/RefIcon";
import QRCode from "react-qr-code";

const Referral = () => {
    const svgRef = useRef();
    const [levelChangeModal, setLevelChangeModal] = useState(false);
    const [placeEditModal, setPlaceEditModal] = useState(false);
    const [globalSearch, setGlobalSearch] = useState("");
    const [globalSearchModal, setGlobalSearchModal] = useState(false);
    const [refDetails, setRefDetails] = useState({});
    const [error, setError] = useState("");
    const [refList, setRefList] = useState("places");
    const [levels, setLevels] = useState({
            level1: 0,
            level2: 0,
            level3: 0,
            level4: 0,
            level5: 0,
            level6: 0,
            level7: 0,
        })

    const downloadQRCode = () => {
        const svg = svgRef.current.querySelector("svg");
        const svgData = new XMLSerializer().serializeToString(svg);
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");

        const img = new Image();
        const svgBlob = new Blob([svgData], { type: "image/svg+xml;charset=utf-8" });
        const url = URL.createObjectURL(svgBlob);

        img.onload = () => {
            canvas.width = img.width;
            canvas.height = img.height;
            ctx.drawImage(img, 0, 0);
            URL.revokeObjectURL(url);

            // Сохранение как PNG
            const pngUrl = canvas.toDataURL("image/png");
            const downloadLink = document.createElement("a");
            downloadLink.href = pngUrl;
            downloadLink.download = "qr-code.png";
            downloadLink.click();
        };

        img.src = url;
    };

    const [levelsCount, setLevelsCount] = useState("0 уровней");
    const updateLevel = () => {
        axios.post("https://backend.sorpa.com/users/ref/credentials/get/edit/", {...levels}).then(
            res => {
                setLevels(res.data.levels);
                setLevelsCount(res.data.text);
                setLevelChangeModal(false);
            }).catch(
            err => {console.log(err)}
        )
    }

    const updateRefCode = () => {
        axios.post(`https://backend.sorpa.com/users/ref/detail/${refDetails.ref_id}/`, {...refDetails}).then(
            res => {
                setRefDetails(res.data);
            }
        ).catch(
            err => {
                console.log(err);
                setError(err.response.data.message);
            }
        )
    }

    const [isLoading, setIsLoading] = useState(true);
    const [activeTab, setActiveTab] = useState("places");
    const history = useNavigate();
    const [count, setCount] = useState(0);
    const [search, setSearch] = useState({});
    const [refs, setRefs] = useState([]);
    const {filterCities, filterCategories, setFilterCategories, setFilterCities} = useContext(AuthContext);
    const [existSearch, setExistSearch] = useState("");
    const [hasMore, setHasMore] = React.useState(false);
    let url = `https://backend.sorpa.com/users/ref/places/list/`;
    let list = useAsyncList({
        async load({signal, cursor}) {
            if (cursor && !cursor.includes("localhost")) {
                setIsLoading(false);
            }
            const res = await axios.get(cursor ? cursor : url);
            let json = await res.data
            setIsLoading(false);
            setRefs(json.results);
            setCount(json.count);
            setHasMore(json?.next !== null);

            const decodedURL = decodeURIComponent(json?.next);

            if (Array.isArray(json.results)){
                return {
                    items: json?.results,
                    cursor: decodedURL,
                };
            }else{
                return {
                    items: [],
                    cursor: null,
                };
            }
        },
    });
    const [loaderRef, scrollerRef] = useInfiniteScroll({hasMore, onLoadMore: list.loadMore});
    useEffect(() => {
        const searchParams = new URLSearchParams(search);
        if (activeTab === 'places'){
            url = `https://backend.sorpa.com/users/ref/places/list/?${searchParams}`;
        }else{
            url = `https://backend.sorpa.com/users/ref/users/list/?${searchParams}`;
        }
        list.reload();
    }, [search]);
    useEffect(() => {
        axios.get("https://backend.sorpa.com/users/ref/credentials/get/edit/").then(
            res => {
                setLevels(res.data.levels);
                setLevelsCount(res.data.text);
            }).catch(
                err => {console.log(err)}
        )
        axios.get("https://backend.sorpa.com/properties/admin/list/").then(
            res => {
                let categories = []
                let cities = []
                res.data.sub_categories.map((item) => {
                    if (item.id !== 0) {
                        categories.push(item)
                    }
                })
                res.data.cities.map((item) => {
                    if (item.id !== 0) {
                        cities.push(item)
                    }
                })
                setFilterCategories(categories);
                setFilterCities(cities);
            }
        ).catch(
            err => {
                console.log(err.response?.data?.message);
            }
        )
    }, []);
    return (
        <div className={"w-full bg-[#121212] h-screen p-[20px] rounded-[24px] border-[20px] border-[black]"}>
            <div className={"flex items-center gap-[24px]"}>
                <Button
                    onClick={() => {
                        setLevelChangeModal(true);
                    }}
                    style={{
                        width: 240,
                        border: "1px solid #BDFF00",
                        background: "none",
                        color: "#BDFF00",
                        fontWeight: 600,
                        borderRadius: 16
                    }}
                >
                    <p>Редактировать</p>
                </Button>
                <Button
                    onClick={() => {
                        setGlobalSearchModal(true);
                    }}
                    style={{
                        width: 240,
                        border: "1px solid #BDFF00",
                        background: "none",
                        color: "#BDFF00",
                        fontWeight: 600,
                        borderRadius: 16
                    }}
                >
                    <p>Глобальный поиск</p>
                </Button>
                <p className={"text-white font-bold"}>{levelsCount}</p>
            </div>
            <div className={"mt-[24px]"}>
                <Tabs aria-label="Options"
                      variant={"solid"}
                      color={"white"}
                      classNames={{
                          tabList: "tablist-back",
                          cursor: "tablist-tab",
                          tabContent: "tablist-text group-data-[selected=true]:text-tablist-text"
                      }}
                      onSelectionChange={(tabName) => {
                          if (tabName === "places"){
                              setActiveTab("places");
                              url = `https://backend.sorpa.com/users/ref/places/list/`
                          }else{
                              setActiveTab("users");
                              url = `https://backend.sorpa.com/users/ref/users/list/`
                          }
                          list.reload();
                      }}
                >
                    <Tab key="places" title="Предприятия">
                        <div style={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "flex-start",
                            gap: 24,
                            alignItems: "center",
                            marginTop:12
                        }}>
                            <div style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "flex-start",
                                gap: 4,
                                alignItems: "center"
                            }}>
                                <div style={{width: 240}}>
                                    <Input
                                        startContent={<SearchIcon/>}
                                        type={"email"}
                                        size={"sm"}
                                        radius="lg"
                                        style={{color: "white"}}
                                        className={"input-text-color"}
                                        classNames={{
                                            label: "input-text-color",
                                            input: "text-white/90",
                                            innerWrapper: "input-text-color",
                                            inputWrapper: "input-main",
                                        }}
                                        placeholder={"Поиск"}
                                        onChange={(event) => {
                                            setSearch({...search, search: event.target.value});
                                            if (event.target.value === "") {
                                                const temp_search = {...search}
                                                delete temp_search.search
                                                setSearch(temp_search);
                                            }
                                        }}
                                    />
                                </div>
                                {/*<Button style={{*/}
                                {/*    height: "44px",*/}
                                {/*    background: "#BF0",*/}
                                {/*    color: "black",*/}
                                {/*    fontWeight: 600*/}
                                {/*}}*/}
                                {/*        onClick={submitSearch}*/}
                                {/*>*/}
                                {/*    <p>Найти</p>*/}
                                {/*</Button>*/}
                            </div>
                            <div style={{width: 240}}>
                                <Select
                                    placeholder={"Все города"}
                                    value={search.city_id}
                                    onChange={(items) => {
                                        setSearch({...search, city_id: items.target.value});
                                        if (!items.target.value || items.target.value === "0") {
                                            const temp_search = {...search}
                                            delete temp_search.city_id
                                            setSearch(temp_search);
                                        }
                                    }}
                                    listboxProps={{
                                        itemClasses: {
                                            base: "wrapper-content"
                                        },
                                    }}
                                    popoverProps={{
                                        classNames: {
                                            content: "wrapper",
                                        },
                                    }}
                                    disallowEmptySelection={false}
                                    color={"secondary"}
                                    size={"sm"}
                                    style={{borderRadius: 16, background: "#2A2A2D", color: "white"}}
                                    classNames={{
                                        label: "text-white",
                                    }}
                                >
                                    {filterCities.map((city) => (
                                        <SelectItem key={city.id} value={city.name}>
                                            {city.name}
                                        </SelectItem>
                                    ))}
                                </Select>
                            </div>
                            <div style={{width: 240}}>
                                <Select
                                    placeholder={"Все категории"}
                                    value={search.category_id}
                                    onChange={(items) => {
                                        setSearch({...search, category_id: items.target.value});
                                        if (!items.target.value || items.target.value === "0") {
                                            const temp_search = {...search}
                                            delete temp_search.category_id
                                            setSearch(temp_search);
                                        }
                                    }}
                                    listboxProps={{
                                        itemClasses: {
                                            base: "wrapper-content"
                                        },
                                    }}
                                    popoverProps={{
                                        classNames: {
                                            content: "wrapper",
                                        },
                                    }}
                                    disallowEmptySelection={false}
                                    color={"secondary"}
                                    size={"sm"}
                                    style={{borderRadius: 16, background: "#2A2A2D", color: "white"}}
                                    classNames={{
                                        label: "text-white",
                                    }}
                                >
                                    {filterCategories.map((category) => (
                                        <SelectItem key={category.id} value={category.name}>
                                            {category.name}
                                        </SelectItem>
                                    ))}
                                </Select>
                            </div>
                        </div>
                        <div className={"mt-[24px]"}>
                            {
                                count === 0 ?
                                    <div style={{
                                        width: "100%",
                                        height: "calc(100vh - 250px)",
                                        display: "flex",
                                        justifyContent: "center"
                                    }}>
                                        <>
                                            {
                                                isLoading ? <Spinner color={"primary"}/>
                                                    :
                                                    <p style={{textAlign: "center", fontWeight: "bold", color: "white"}}>По
                                                        вашему запросу ничего не найдено</p>
                                            }
                                        </>
                                    </div> :
                                    <Table
                                        isStriped isHeaderSticky
                                        classNames={{
                                            base: 'table-wrapper',
                                            table: "table",
                                            wrapper: "base-wrapper max-w-[1500px]"
                                        }}
                                        className={"h-full"}
                                        aria-label="Example table with infinite pagination"
                                        baseRef={scrollerRef}
                                        bottomContent={
                                            hasMore ? (
                                                <div ref={loaderRef} className="flex w-full justify-center">
                                                    {/*<Spinner  color="transparent" className={"bg-transparent text-transparent"} />*/}
                                                </div>
                                            ) : null
                                        }
                                    >
                                        <TableHeader>
                                            <TableColumn style={{
                                                background: "#525255",
                                                color: "white",
                                                fontWeight: "bold"
                                            }}>Наименование</TableColumn>
                                            <TableColumn style={{
                                                background: "#525255",
                                                color: "white",
                                                fontWeight: "bold"
                                            }}>Город</TableColumn>
                                            <TableColumn style={{
                                                background: "#525255",
                                                color: "white",
                                                fontWeight: "bold"
                                            }}>Категория</TableColumn>
                                            <TableColumn style={{
                                                background: "#525255",
                                                color: "white",
                                                fontWeight: "bold"
                                            }}>Пользователей в сетке</TableColumn>
                                            <TableColumn style={{
                                                background: "#525255",
                                                color: "white",
                                                fontWeight: "bold"
                                            }}>Бонусных баллов</TableColumn>
                                            <TableColumn style={{
                                                background: "#525255",
                                                color: "white",
                                                fontWeight: "bold",
                                                textAlign: "center"
                                            }}>Действия</TableColumn>
                                        </TableHeader>
                                        <TableBody
                                            isLoading={isLoading}
                                            items={list.items}
                                            // loadingContent={<Spinner color="transparent" className={"bg-transparent -transparent"}/>}
                                        >
                                            {(item) => (
                                                <TableRow>
                                                    <TableCell><p style={{
                                                        borderRight: "1px solid rgba(84, 84, 88, 0.65)",
                                                        margin: 0
                                                    }}>{item.name}</p></TableCell>
                                                    <TableCell><p style={{
                                                        borderRight: "1px solid rgba(84, 84, 88, 0.65)",
                                                        margin: 0
                                                    }}>{item.city}</p></TableCell>
                                                    <TableCell><p style={{
                                                        borderRight: "1px solid rgba(84, 84, 88, 0.65)",
                                                        margin: 0
                                                    }}>{item.category}</p></TableCell>
                                                    <TableCell><p style={{
                                                        borderRight: "1px solid rgba(84, 84, 88, 0.65)",
                                                        margin: 0
                                                    }}>{item.net_count}</p></TableCell>
                                                    <TableCell><p style={{
                                                        borderRight: "1px solid rgba(84, 84, 88, 0.65)",
                                                        margin: 0
                                                    }}>{item.sum}</p></TableCell>
                                                    <TableCell style={{width: 120}}>
                                                        <div style={{
                                                            display: "flex",
                                                            justifyContent: "center"
                                                        }}>
                                                            <Tooltip content="Просмотреть сетку">
                                                                <Button isIconOnly={true}
                                                                        onClick={() => {history(`/referral/${item.id}`)}}
                                                                        style={{background: "none"}}>
                                                                    <RefIcon color={"white"}/>
                                                                </Button>
                                                            </Tooltip>
                                                            <Tooltip content="Редактировать">
                                                                <Button onClick={() => {
                                                                    axios.get(`https://backend.sorpa.com/users/ref/detail/${item.id}/`).then(
                                                                        res => {
                                                                            setRefDetails(res.data);
                                                                            setPlaceEditModal(true);
                                                                        }
                                                                    ).catch(
                                                                        err => {
                                                                            console.log(err)
                                                                        }
                                                                    )
                                                                }} isIconOnly={true}
                                                                        style={{background: "none"}}>
                                                                    <EditIcon/>
                                                                </Button>
                                                            </Tooltip>
                                                        </div>
                                                    </TableCell>
                                                </TableRow>
                                            )}
                                        </TableBody>
                                    </Table>
                            }
                        </div>
                    </Tab>
                    <Tab key="users" title="Свободный пользователь">
                        <div style={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "flex-start",
                            gap: 24,
                            marginTop:12,
                            alignItems: "center"
                        }}>
                            <div style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "flex-start",
                                gap: 4,
                                alignItems: "center"
                            }}>
                                <div style={{width: 240}}>
                                    <Input
                                        startContent={<SearchIcon/>}
                                        type={"email"}
                                        size={"sm"}
                                        radius="lg"
                                        style={{color: "white"}}
                                        className={"input-text-color"}
                                        classNames={{
                                            label: "input-text-color",
                                            input: "text-white/90",
                                            innerWrapper: "input-text-color",
                                            inputWrapper: "input-main",
                                        }}
                                        placeholder={"Поиск"}
                                        onChange={(event) => {
                                            setSearch({...search, search: event.target.value});
                                            if (event.target.value === "") {
                                                const temp_search = {...search}
                                                delete temp_search.search
                                                setSearch(temp_search);
                                            }
                                        }}
                                    />
                                </div>
                                {/*<Button style={{*/}
                                {/*    height: "44px",*/}
                                {/*    background: "#BF0",*/}
                                {/*    color: "black",*/}
                                {/*    fontWeight: 600*/}
                                {/*}}*/}
                                {/*        onClick={submitSearch}*/}
                                {/*>*/}
                                {/*    <p>Найти</p>*/}
                                {/*</Button>*/}
                            </div>
                            <div style={{width: 240}}>
                                <Select
                                    placeholder={"Все города"}
                                    value={search.city_id}
                                    onChange={(items) => {
                                        setSearch({...search, city_id: items.target.value});
                                        if (!items.target.value || items.target.value === "0") {
                                            const temp_search = {...search}
                                            delete temp_search.city_id
                                            setSearch(temp_search);
                                        }
                                    }}
                                    listboxProps={{
                                        itemClasses: {
                                            base: "wrapper-content"
                                        },
                                    }}
                                    popoverProps={{
                                        classNames: {
                                            content: "wrapper",
                                        },
                                    }}
                                    disallowEmptySelection={false}
                                    color={"secondary"}
                                    size={"sm"}
                                    style={{borderRadius: 16, background: "#2A2A2D", color: "white"}}
                                    classNames={{
                                        label: "text-white",
                                    }}
                                >
                                    {filterCities.map((city) => (
                                        <SelectItem key={city.id} value={city.name}>
                                            {city.name}
                                        </SelectItem>
                                    ))}
                                </Select>
                            </div>
                        </div>
                        <div className={"mt-[24px]"}>
                            {
                                count === 0 ?
                                    <div style={{
                                        width: "100%",
                                        height: "calc(100vh - 250px)",
                                        display: "flex",
                                        justifyContent: "center"
                                    }}>
                                        <>
                                            {
                                                isLoading ? <Spinner color={"primary"}/>
                                                    :
                                                    <p style={{textAlign: "center", fontWeight: "bold", color: "white"}}>По
                                                        вашему запросу ничего не найдено</p>
                                            }
                                        </>
                                    </div> :
                                    <Table
                                        isStriped isHeaderSticky
                                        classNames={{
                                            base: 'table-wrapper',
                                            table: "table",
                                            wrapper: "base-wrapper max-w-[1500px]"
                                        }}
                                        className={"h-full"}
                                        aria-label="Example table with infinite pagination"
                                        baseRef={scrollerRef}
                                        bottomContent={
                                            hasMore ? (
                                                <div ref={loaderRef} className="flex w-full justify-center">
                                                    {/*<Spinner  color="transparent" className={"bg-transparent text-transparent"} />*/}
                                                </div>
                                            ) : null
                                        }
                                    >
                                        <TableHeader>
                                            <TableColumn style={{
                                                background: "#525255",
                                                color: "white",
                                                fontWeight: "bold"
                                            }}>Наименование</TableColumn>
                                            <TableColumn style={{
                                                background: "#525255",
                                                color: "white",
                                                fontWeight: "bold"
                                            }}>Город</TableColumn>
                                            <TableColumn style={{
                                                background: "#525255",
                                                color: "white",
                                                fontWeight: "bold"
                                            }}>Номер телефона</TableColumn>
                                            <TableColumn style={{
                                                background: "#525255",
                                                color: "white",
                                                fontWeight: "bold"
                                            }}>Пользователей в сетке</TableColumn>
                                            <TableColumn style={{
                                                background: "#525255",
                                                color: "white",
                                                fontWeight: "bold"
                                            }}>Бонусных баллов</TableColumn>
                                            <TableColumn style={{
                                                background: "#525255",
                                                color: "white",
                                                fontWeight: "bold",
                                                textAlign: "center"
                                            }}>Действия</TableColumn>
                                        </TableHeader>
                                        <TableBody
                                            isLoading={isLoading}
                                            items={list.items}
                                            // loadingContent={<Spinner color="transparent" className={"bg-transparent -transparent"}/>}
                                        >
                                            {(item) => (
                                                <TableRow>
                                                    <TableCell><p style={{
                                                        borderRight: "1px solid rgba(84, 84, 88, 0.65)",
                                                        margin: 0
                                                    }}>{item.name}</p></TableCell>
                                                    <TableCell><p style={{
                                                        borderRight: "1px solid rgba(84, 84, 88, 0.65)",
                                                        margin: 0
                                                    }}>{item.city}</p></TableCell>
                                                    <TableCell><p style={{
                                                        borderRight: "1px solid rgba(84, 84, 88, 0.65)",
                                                        margin: 0
                                                    }}>{item.phone_number}</p></TableCell>
                                                    <TableCell><p style={{
                                                        borderRight: "1px solid rgba(84, 84, 88, 0.65)",
                                                        margin: 0
                                                    }}>{item.net_count}</p></TableCell>
                                                    <TableCell><p style={{
                                                        borderRight: "1px solid rgba(84, 84, 88, 0.65)",
                                                        margin: 0
                                                    }}>{item.sum}</p></TableCell>
                                                    <TableCell style={{width: 120}}>
                                                        <div style={{
                                                            display: "flex",
                                                            justifyContent: "center"
                                                        }}>
                                                            <Tooltip content="Просмотреть сетку">
                                                                <Button isIconOnly={true}
                                                                        onClick={() => {history(`/referral/${item.id}`)}}
                                                                        style={{background: "none"}}>
                                                                    <RefIcon color={"white"}/>
                                                                </Button>
                                                            </Tooltip>
                                                        </div>
                                                    </TableCell>
                                                </TableRow>
                                            )}
                                        </TableBody>
                                    </Table>
                            }
                        </div>
                    </Tab>
                </Tabs>
            </div>
            <Modal isOpen={globalSearchModal} style={{background: "#121212"}} hideCloseButton={true}>
                <ModalContent>
                    <ModalHeader style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        paddingBottom: 0,
                        paddingRight: 16
                    }}>
                        <h2 style={{color: "white"}}>Глобальный поиск</h2>
                        <Button onClick={() => {
                            setGlobalSearchModal(false);
                            setError("");
                        }} isIconOnly={true} style={{background: "none", padding: 0}}><CloseIcon/></Button>
                    </ModalHeader>
                    <ModalBody className={"flex flex-col items-center"}>
                        {
                            error !== "" && <p style={{color: "red"}}>{error}</p>
                        }
                        <Input
                            type={"text"}
                            placeholder={"Номер телефона"}
                            label={"Номер телефона"}
                            value={refDetails.ref_code}
                            size={"sm"}
                            radius="lg"
                            style={{color: "white"}}
                            className={"input-text-color"}
                            classNames={{
                                label: "input-text-color",
                                input: "text-white/90",
                                innerWrapper: "input-text-color",
                                inputWrapper: "input-main",
                            }}
                            onChange={(event) => {
                                setGlobalSearch(event.target.value);
                                setError("");
                            }}
                        />
                        <Button style={{
                            background: "#BF0",
                            color: "black",
                            fontSize: 16,
                            fontWeight: 600,
                            marginBottom:20,
                            width:"100%"
                        }}
                                onClick={() => {
                                    axios.post("https://backend.sorpa.com/users/ref/find/user/", {"phone_number": globalSearch}).then(
                                        res => {
                                            history(res.data.link);
                                            setError("");
                                        }
                                    ).catch(
                                        err => {
                                            setError(err.response.data.message);
                                        }
                                    )
                                }}
                        >
                            <p>Найти</p>
                        </Button>
                    </ModalBody>
                </ModalContent>
            </Modal>
            <Modal isOpen={placeEditModal} style={{background: "#121212"}} hideCloseButton={true}>
                <ModalContent>
                    <ModalHeader style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        paddingBottom: 0,
                        paddingRight: 16
                    }}>
                        <h2 style={{color: "white"}}>Подробнее</h2>
                        <Button onClick={() => {
                            setPlaceEditModal(false);
                            setError("");
                        }} isIconOnly={true} style={{background: "none", padding: 0}}><CloseIcon/></Button>
                    </ModalHeader>
                    <ModalBody className={"flex flex-col items-center"}>
                        {
                            error !== "" && <p style={{color: "red"}}>{error}</p>
                        }
                        <div ref={svgRef}>
                            <QRCode
                                fgColor={"black"}
                                bgColor={"#EFEFEF"}
                                title={"ASP CLUB"}
                                size={256}
                                style={{height: "auto", maxWidth: "200px", width: "100%", borderWidth:16, borderRadius: 16}}
                                value={`${refDetails.ref_link}`}
                                viewBox={`0 0 256 256`}
                            />
                        </div>
                        <Button style={{
                            background: "white",
                            color: "black",
                            fontSize: 16,
                            fontWeight: 600,
                            marginBottom:20,
                            width: 200
                        }}
                                onClick={downloadQRCode}
                        >
                            <p>Скачать</p>
                        </Button>
                        <Input
                            maxLength={8}
                            type={"text"}
                            placeholder={"Введите промокод"}
                            label={"Промокод"}
                            value={refDetails.ref_code}
                            size={"sm"}
                            radius="lg"
                            style={{color: "white"}}
                            className={"input-text-color"}
                            classNames={{
                                label: "input-text-color",
                                input: "text-white/90",
                                innerWrapper: "input-text-color",
                                inputWrapper: "input-main",
                            }}
                            onChange={(event) => {
                                setError("");
                                setRefDetails({...refDetails, ref_code: event.target.value});
                            }}
                        />
                        <Button style={{
                            background: "#BF0",
                            color: "black",
                            fontSize: 16,
                            fontWeight: 600,
                            marginBottom:20,
                            width:"100%"
                        }}
                                onClick={updateRefCode}
                        >
                            <p>Сохранить</p>
                        </Button>
                    </ModalBody>
                </ModalContent>
            </Modal>
            <Modal isOpen={levelChangeModal} style={{background: "#121212"}} hideCloseButton={true}>
                <ModalContent>
                    <ModalHeader style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        paddingBottom: 0,
                        paddingRight: 16
                    }}>
                        <h2 style={{color: "white"}}>Редактировать уровни</h2>
                        <Button onClick={() => {
                            setLevelChangeModal(false);
                            setError("");
                        }} isIconOnly={true} style={{background: "none", padding: 0}}><CloseIcon/></Button>
                    </ModalHeader>
                    <ModalBody>
                        {
                            error !== "" && <p style={{color: "red"}}>{error}</p>
                        }
                        <RefInput value={levels.level1} label={"Уровень 1 (%)"} levels={levels} levelChange={setLevels} level={"level1"}/>
                        <RefInput value={levels.level2} label={"Уровень 2 (%)"} levels={levels} levelChange={setLevels} level={"level2"}/>
                        <RefInput value={levels.level3} label={"Уровень 3 (%)"} levels={levels} levelChange={setLevels} level={"level3"}/>
                        <RefInput value={levels.level4} label={"Уровень 4 (%)"} levels={levels} levelChange={setLevels} level={"level4"}/>
                        <RefInput value={levels.level5} label={"Уровень 5 (%)"} levels={levels} levelChange={setLevels} level={"level5"}/>
                        <RefInput value={levels.level6} label={"Уровень 6 (%)"} levels={levels} levelChange={setLevels} level={"level6"}/>
                        <RefInput value={levels.level7} label={"Уровень 7 (%)"} levels={levels} levelChange={setLevels} level={"level7"}/>
                        <p className={"font-bold text-white text-center"}>0% означает отключенный уровень</p>
                        <Button style={{
                            background: "#BF0",
                            color: "black",
                            fontSize: 16,
                            fontWeight: 600,
                            marginBottom:20
                        }}
                                onClick={updateLevel}
                        >
                            <p>Сохранить</p>
                        </Button>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </div>
    )
}

const RefInput = ({label, value, levelChange, level, levels}) => {
    return(
        <Input
            type={"number"}
            placeholder={"Введите число"}
            label={label}
            value={value}
            size={"sm"}
            radius="lg"
            style={{color: "white"}}
            className={"input-text-color"}
            classNames={{
                label: "input-text-color",
                input: "text-white/90",
                innerWrapper: "input-text-color",
                inputWrapper: "input-main",
            }}
            onChange={(event) => {
                let value = event.target.value.replace(/,/g, ".");
                levelChange({...levels, [`${level}`]: value });
            }}
        />
        )
}

export default Referral;
