import {
    Button,
    Card,
    Divider,
    Input,
    Table,
    TableHeader,
    TableColumn,
    TableBody,
    TableRow,
    TableCell,
    Tooltip, Tabs, Tab, ModalContent, ModalHeader, ModalBody, Modal, Textarea
} from "@nextui-org/react";
import {Select, SelectItem} from "@nextui-org/react";
import {SearchIcon} from "../../assets/icons/SearchIcon";
import {useContext, useEffect, useState} from "react";
import {useNavigate} from "react-router";
import {EditIcon} from "../../assets/icons/EditIcon";
import {DeleteIcon} from "../../assets/icons/DeleteIcon";
import axios from "axios";
import {AddIcon} from "../../assets/icons/AddIcon";
import {CloseIcon} from "../../assets/icons/CloseIcon";

const News = () => {
    const history = useNavigate();
    const [search, setSearch] = useState('');
    const [news, setNews] = useState([]);
    const [month, setMonth] = useState("");
    const [months, setMonths] = useState([]);
    const [cities, setCities] = useState([]);
    const [city, setCity] = useState("");
    const [year, setYear] = useState("");
    const [years, setYears] = useState([]);
    const [existSearch, setExistSearch] = useState("");
    const [articleError, setArticleError] = useState("");
    const [newArticle, setNewArticle] = useState(false);
    const [logoPreview, setLogoPreview] = useState(null);
    const [archive, setArchive] = useState([]);
    const [logo, setLogo] = useState(null);
    const [articleData, setArticleData] = useState(
        {
            city:"",
            id: "",
            text: "",
            title: ""
        }
    );
    useEffect(() => {
        let searchParams = `${month || year || search || city ? "?" : ''}${search ? `search=${search}` : ''}${month ? `&month=${month}` : ''}${year ? `&year=${year}` : ''}${city  ? `&city=${city}` : ''}`
        history(searchParams);
        setExistSearch(searchParams);
        axios.post(`https://backend.sorpa.com/news/admin/${searchParams}`).then(
            res => {
                setNews(res.data);
            }
        ).catch(
            err => {
                console.log(err);
            }
        )
    }, [search, month,  year, city]);

    useEffect(() => {
        axios.get('https://backend.sorpa.com/news/admin/').then(
            res => {
                setNews(res.data.news);
                setMonths(res.data.months);
                setYears(res.data.years);
                setCities(res.data.cities);
                console.log(res.data);
            }
        ).catch(
            err => {
                console.log(err);
            }
        )
        axios.get('https://backend.sorpa.com/news/archive/list/').then(
            res => {
                setArchive(res.data);
            }
        ).catch(
            err => {
                console.log(err);
            }
        )
    }, []);

    const headers = {'Content-Type': 'multipart/form-data'};

    const submitArticle = () => {
        const formData = new FormData();
        formData.append("title", articleData.title);
        formData.append("text", articleData.text);
        formData.append("city", articleData.city);
        if (logo){
            formData.append("image", logo);
        }
        if (articleData.id){
            formData.append("article_id", articleData.id);
        }
        axios.post("https://backend.sorpa.com/news/create_or_edit/", formData, {headers: headers}).then(res =>
        {
            setNewArticle(false);
            setArticleData(
                {
                    id: null,
                    image:null,
                    text: "",
                    title: "",
                    city:null
                }
            )
            setNews(res.data);
        }
        ).catch(
            err => {console.log(err);}
        )
    }
    return (
        <>
            <div style={{
                color: "white",
                width: "100%",
                height: "calc(100vh - 72px)",
                boxSizing: "border-box",
                paddingRight: 16,
                paddingLeft: 16,
                paddingBottom: 16,
                marginTop:20
            }}>
                <div style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 16,
                    background: "black",
                    maxWidth: 1300,
                    height: "100%"
                }}>
                    <Card style={{width: "100%", background: "#121212", padding: 24}}>
                        <div style={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "flex-start",
                            gap: 16,
                            marginBottom:40
                        }}>
                            <div style={{color: "white", fontWeight: "bold"}}>
                                <div className="flex w-full flex-col">
                                    <Tabs aria-label="Options"
                                          variant={"solid"}
                                          color={"white"}
                                          classNames={{
                                              tabList: "tablist-back",
                                              cursor: "tablist-tab",
                                              tabContent: "tablist-text group-data-[selected=true]:text-tablist-text"
                                          }}
                                    >
                                        <Tab key="news" title="Новости">
                                            <Card
                                                shadow={"none"}
                                                style={{
                                                width: "100%",
                                                display: "flex",
                                                flexDirection: "column",
                                                justifyContent: "flex-start",
                                                background:"none",
                                                gap: 16,
                                            }}>
                                                    <div
                                                        onClick={() => {setNewArticle(true)}}
                                                        style={{
                                                            maxWidth:180,
                                                            background: "none",
                                                            color: "white",
                                                            fontWeight: 600,
                                                            display:"flex",
                                                            gap: 8,
                                                            cursor:"pointer"
                                                        }}
                                                    >
                                                        <AddIcon/>
                                                        <p>Добавить новость</p>
                                                    </div>
                                                    <p style={{color: "white", fontWeight: "bold"}}>{news.length} новостей</p>

                                                    <div style={{
                                                        width: "100%",
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        justifyContent: "flex-start",
                                                        gap: 24,
                                                        alignItems: "center",
                                                    }}>
                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            justifyContent: "flex-start",
                                                            gap: 4,
                                                            alignItems: "center"
                                                        }}>
                                                            <div style={{width: 240}}>
                                                                <Input
                                                                    startContent={<SearchIcon/>}
                                                                    type={"email"}
                                                                    size={"sm"}
                                                                    radius="lg"
                                                                    style={{color: "white"}}
                                                                    className={"input-text-color"}
                                                                    classNames={{
                                                                        label: "input-text-color",
                                                                        input: "text-white/90",
                                                                        innerWrapper: "input-text-color",
                                                                        inputWrapper: "input-main",
                                                                    }}
                                                                    placeholder={"Поиск по заголовку"}
                                                                    onChange={(event) => {
                                                                        setSearch(event.target.value);
                                                                        if (event.target.value === '') {
                                                                            setSearch('');
                                                                            setExistSearch('');
                                                                        }
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div style={{width: 240}}>
                                                            <Select
                                                                placeholder={"Город"}
                                                                value={city}
                                                                onChange={(items) => {
                                                                    setCity(items.target.value);
                                                                    if (!items.target.value || items.target.value === "0") {
                                                                        setCity("");
                                                                    }
                                                                }}
                                                                listboxProps={{
                                                                    itemClasses: {
                                                                        base: "wrapper-content"
                                                                    },
                                                                }}
                                                                popoverProps={{
                                                                    classNames: {
                                                                        content: "wrapper",
                                                                    },
                                                                }}
                                                                disallowEmptySelection={false}
                                                                color={"secondary"}
                                                                size={"sm"}
                                                                style={{borderRadius: 16, background: "#2A2A2D", color: "white"}}
                                                                classNames={{
                                                                    label: "text-white",
                                                                }}
                                                            >
                                                                {cities?.map((city) => (
                                                                    <SelectItem key={city.id} value={city.name}>
                                                                        {city.name}
                                                                    </SelectItem>
                                                                ))}
                                                            </Select>
                                                        </div>
                                                        <div style={{width: 240}}>
                                                            <Select
                                                                placeholder={"Месяц"}
                                                                value={month}
                                                                onChange={(items) => {
                                                                    setMonth(items.target.value);
                                                                    if (!items.target.value || items.target.value === "0") {
                                                                        setMonth("");
                                                                    }
                                                                }}
                                                                listboxProps={{
                                                                    itemClasses: {
                                                                        base: "wrapper-content"
                                                                    },
                                                                }}
                                                                popoverProps={{
                                                                    classNames: {
                                                                        content: "wrapper",
                                                                    },
                                                                }}
                                                                disallowEmptySelection={false}
                                                                color={"secondary"}
                                                                size={"sm"}
                                                                style={{borderRadius: 16, background: "#2A2A2D", color: "white"}}
                                                                classNames={{
                                                                    label: "text-white",
                                                                }}
                                                            >
                                                                {months.map((month) => (
                                                                    <SelectItem key={month.id} value={month.name}>
                                                                        {month.name}
                                                                    </SelectItem>
                                                                ))}
                                                            </Select>
                                                        </div>
                                                        <div style={{width: 240}}>
                                                            <Select
                                                                placeholder={"Год"}
                                                                value={year}
                                                                onChange={(items) => {
                                                                    setYear(items.target.value);
                                                                    if (!items.target.value || items.target.value === "0") {
                                                                        setYear("");
                                                                    }
                                                                }}
                                                                listboxProps={{
                                                                    itemClasses: {
                                                                        base: "wrapper-content"
                                                                    },
                                                                }}
                                                                popoverProps={{
                                                                    classNames: {
                                                                        content: "wrapper",
                                                                    },
                                                                }}
                                                                disallowEmptySelection={false}
                                                                color={"secondary"}
                                                                size={"sm"}
                                                                style={{borderRadius: 16, background: "#2A2A2D", color: "white"}}
                                                                classNames={{
                                                                    label: "text-white",
                                                                }}
                                                            >
                                                                {years.map((item) => (
                                                                    <SelectItem key={item.id} value={item.name}>
                                                                        {item.name}
                                                                    </SelectItem>
                                                                ))}
                                                            </Select>
                                                        </div>
                                                    </div>
                                                    <Divider style={{background: "rgba(84, 84, 88, 0.65)"}}/>
                                                    <div>
                                                        {
                                                            news.length === 0 ?
                                                                <div style={{
                                                                    width: "100%",
                                                                    height: "calc(100vh - 250px)",
                                                                    display: "flex",
                                                                    justifyContent: "center",
                                                                }}>
                                                                    <p style={{textAlign: "center", fontWeight: "bold", color: "white"}}>По
                                                                        вашему запросу ничего не найдено</p>
                                                                </div> :
                                                                <>
                                                                    {
                                                                        localStorage.getItem("role") === "Владелец" ?
                                                                            <Table isStriped isHeaderSticky aria-label="Example static collection table"
                                                                                   classNames={{
                                                                                       base: 'table-wrapper',
                                                                                       table: "table",
                                                                                       wrapper: "base-wrapper"
                                                                                   }}
                                                                            >
                                                                                <TableHeader>
                                                                                    <TableColumn style={{
                                                                                        background: "#525255",
                                                                                        color: "white",
                                                                                        fontWeight: "bold"
                                                                                    }}>Наименование</TableColumn>
                                                                                    <TableColumn style={{
                                                                                        background: "#525255",
                                                                                        color: "white",
                                                                                        fontWeight: "bold"
                                                                                    }}>Дата</TableColumn>
                                                                                    <TableColumn style={{
                                                                                        background: "#525255",
                                                                                        color: "white",
                                                                                        fontWeight: "bold"
                                                                                    }}>Город</TableColumn>
                                                                                    <TableColumn style={{
                                                                                        background: "#525255",
                                                                                        color: "white",
                                                                                        fontWeight: "bold",
                                                                                        textAlign: "center"
                                                                                    }}>Показан в приложении?</TableColumn>
                                                                                </TableHeader>
                                                                                <TableBody>
                                                                                    {
                                                                                        news.map((article, index) => {
                                                                                            return (
                                                                                                <TableRow key={index}>
                                                                                                    <TableCell><p style={{
                                                                                                        borderRight: "1px solid rgba(84, 84, 88, 0.65)",
                                                                                                        margin: 0
                                                                                                    }}>{article.title}</p></TableCell>
                                                                                                    <TableCell><p style={{
                                                                                                        borderRight: "1px solid rgba(84, 84, 88, 0.65)",
                                                                                                        margin: 0
                                                                                                    }}>{article.created_at}</p></TableCell>
                                                                                                    <TableCell><p style={{
                                                                                                        borderRight: "1px solid rgba(84, 84, 88, 0.65)",
                                                                                                        margin: 0
                                                                                                    }}>{article.city}</p></TableCell>
                                                                                                    <TableCell style={{width: 120}}>
                                                                                                        <div style={{
                                                                                                            display: "flex",
                                                                                                            justifyContent: "center"
                                                                                                        }}>
                                                                                                            <p style={{
                                                                                                                margin: 0
                                                                                                            }}>{article.is_shown ? "Да" : "Нет"}</p>
                                                                                                        </div>
                                                                                                    </TableCell>
                                                                                                </TableRow>
                                                                                            )
                                                                                        })
                                                                                    }
                                                                                </TableBody>
                                                                            </Table> :
                                                                            <Table isStriped isHeaderSticky aria-label="Example static collection table"
                                                                                   classNames={{
                                                                                       base: 'table-wrapper',
                                                                                       table: "table",
                                                                                       wrapper: "base-wrapper"
                                                                                   }}
                                                                            >
                                                                                <TableHeader>
                                                                                    <TableColumn style={{
                                                                                        background: "#525255",
                                                                                        color: "white",
                                                                                        fontWeight: "bold"
                                                                                    }}>Наименование</TableColumn>
                                                                                    <TableColumn style={{
                                                                                        background: "#525255",
                                                                                        color: "white",
                                                                                        fontWeight: "bold"
                                                                                    }}>Дата</TableColumn>
                                                                                    <TableColumn style={{
                                                                                        background: "#525255",
                                                                                        color: "white",
                                                                                        fontWeight: "bold"
                                                                                    }}>Город</TableColumn>
                                                                                    <TableColumn style={{
                                                                                        background: "#525255",
                                                                                        color: "white",
                                                                                        fontWeight: "bold"
                                                                                    }}>Показан в приложении?</TableColumn>
                                                                                    <TableColumn style={{
                                                                                        background: "#525255",
                                                                                        color: "white",
                                                                                        fontWeight: "bold",
                                                                                        textAlign: "center"
                                                                                    }}>Действия</TableColumn>
                                                                                </TableHeader>
                                                                                <TableBody>
                                                                                    {
                                                                                        news.map((article, index) => {
                                                                                            return (
                                                                                                <TableRow key={index}>
                                                                                                    <TableCell><p style={{
                                                                                                        borderRight: "1px solid rgba(84, 84, 88, 0.65)",
                                                                                                        margin: 0
                                                                                                    }}>{article.title}</p></TableCell>
                                                                                                    <TableCell><p style={{
                                                                                                        borderRight: "1px solid rgba(84, 84, 88, 0.65)",
                                                                                                        margin: 0
                                                                                                    }}>{article.created_at}</p></TableCell>
                                                                                                    <TableCell><p style={{
                                                                                                        borderRight: "1px solid rgba(84, 84, 88, 0.65)",
                                                                                                        margin: 0
                                                                                                    }}>{article.city}</p></TableCell>
                                                                                                    <TableCell><p style={{
                                                                                                        borderRight: "1px solid rgba(84, 84, 88, 0.65)",
                                                                                                        margin: 0
                                                                                                    }}>{article.is_shown ? "Да" : "Нет"}</p></TableCell>
                                                                                                    <TableCell style={{width: 120}}>
                                                                                                        <div style={{
                                                                                                            display: "flex",
                                                                                                            justifyContent: "center"
                                                                                                        }}>
                                                                                                            <Tooltip content="Редактировать">
                                                                                                                <Button isIconOnly={true}
                                                                                                                        onClick={
                                                                                                                            () => {
                                                                                                                                axios.get(`https://backend.sorpa.com/news/${article.id}/`).then(
                                                                                                                                    res => {
                                                                                                                                        setArticleData({
                                                                                                                                                id: article.id,
                                                                                                                                                text: res.data.text,
                                                                                                                                                title: res.data.title
                                                                                                                                            }
                                                                                                                                        )
                                                                                                                                        setLogoPreview(
                                                                                                                                            `https://backend.sorpa.com${res.data.image}`
                                                                                                                                        )
                                                                                                                                        setNewArticle(true);
                                                                                                                                    }
                                                                                                                                )
                                                                                                                            }
                                                                                                                        }
                                                                                                                        style={{background: "none"}}>
                                                                                                                    <EditIcon/>
                                                                                                                </Button>
                                                                                                            </Tooltip>
                                                                                                            <Tooltip content="Удалить">
                                                                                                                <Button onClick={() => {
                                                                                                                    axios.post(`https://backend.sorpa.com/news/archive/`, {article_id: article.id}).then(
                                                                                                                        res => {
                                                                                                                            setNews(res.data);
                                                                                                                            axios.get('https://backend.sorpa.com/news/archive/list/').then(
                                                                                                                                res => {
                                                                                                                                    setArchive(res.data);
                                                                                                                                }
                                                                                                                            ).catch(
                                                                                                                                err => {
                                                                                                                                    console.log(err);
                                                                                                                                }
                                                                                                                            )
                                                                                                                        }
                                                                                                                    ).catch(
                                                                                                                        err => {
                                                                                                                            console.log(err)
                                                                                                                        }
                                                                                                                    )
                                                                                                                }} isIconOnly={true}
                                                                                                                        style={{background: "none"}}>
                                                                                                                    <DeleteIcon/>
                                                                                                                </Button>
                                                                                                            </Tooltip>
                                                                                                        </div>
                                                                                                    </TableCell>
                                                                                                </TableRow>
                                                                                            )
                                                                                        })
                                                                                    }
                                                                                </TableBody>
                                                                            </Table>
                                                                    }
                                                                </>
                                                        }
                                                    </div>
                                            </Card>
                                        </Tab>
                                        <Tab key="archive" title="Архив">
                                            <Card
                                                shadow={"none"}
                                                style={{
                                                    width: "100%",
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    justifyContent: "flex-start",
                                                    background:"none",
                                                    gap: 16,
                                                }}>
                                                <p style={{color: "white", fontWeight: "bold"}}>{archive.length} новостей</p>
                                                <Divider style={{background: "rgba(84, 84, 88, 0.65)"}}/>
                                                <div>
                                                    {
                                                        archive.length === 0 ?
                                                            <div style={{
                                                                width: "100%",
                                                                height: "calc(100vh - 250px)",
                                                                display: "flex",
                                                                justifyContent: "center",
                                                            }}>
                                                                <p style={{textAlign: "center", fontWeight: "bold", color: "white"}}>По
                                                                    вашему запросу ничего не найдено</p>
                                                            </div> :
                                                            <Table isStriped isHeaderSticky aria-label="Example static collection table"
                                                                   classNames={{
                                                                       base: 'table-wrapper',
                                                                       table: "table",
                                                                       wrapper: "base-wrapper"
                                                                   }}
                                                            >
                                                                <TableHeader>
                                                                    <TableColumn style={{
                                                                        background: "#525255",
                                                                        color: "white",
                                                                        fontWeight: "bold"
                                                                    }}>Наименование</TableColumn>
                                                                    <TableColumn style={{
                                                                        background: "#525255",
                                                                        color: "white",
                                                                        fontWeight: "bold"
                                                                    }}>Дата</TableColumn>
                                                                </TableHeader>
                                                                <TableBody>
                                                                    {
                                                                        archive.map((article, index) => {
                                                                            return (
                                                                                <TableRow key={index}>
                                                                                    <TableCell><p style={{
                                                                                        borderRight: "1px solid rgba(84, 84, 88, 0.65)",
                                                                                        margin: 0
                                                                                    }}>{article.title}</p></TableCell>
                                                                                    <TableCell><p style={{
                                                                                        margin: 0
                                                                                    }}>{article.created_at}</p></TableCell>
                                                                                </TableRow>
                                                                            )
                                                                        })
                                                                    }
                                                                </TableBody>
                                                            </Table>
                                                    }
                                                </div>
                                            </Card>
                                        </Tab>
                                    </Tabs>
                                </div>
                            </div>
                        </div>
                    </Card>
                </div>
                <Modal isOpen={newArticle} style={{background: "#121212"}} hideCloseButton={true}>
                    <ModalContent>
                        <ModalHeader style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            paddingBottom: 0,
                            paddingRight: 16
                        }}>
                            <h2 style={{color: "white"}}>Добавить новость</h2>
                            <Button onClick={() => {
                                setNewArticle(false);
                                setArticleData(
                                    {
                                        id: null,
                                        image:null,
                                        text: "",
                                        title: ""
                                    }
                                )
                            }} isIconOnly={true} style={{background: "none", padding: 0}}><CloseIcon/></Button>
                        </ModalHeader>
                        <ModalBody>
                            {
                                articleError && <p style={{color: "red"}}>{articleError}</p>
                            }
                            <div>
                                {
                                    logoPreview ?
                                        <div style={{
                                            width: "100%",
                                            height: 180,
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "center",
                                            justifyContent: "center"
                                        }}>
                                            <img style={{
                                                borderRadius: 12,
                                                height: 160,
                                                width: "100%",
                                                objectFit: "contain",
                                                background: "#2A2A2C"
                                            }} src={logoPreview}/>
                                            <label htmlFor={"logo-input"}><p style={{
                                                fontSize: 12,
                                                fontWeight: "bold",
                                                marginTop: 4,
                                                textAlign: "center",
                                                cursor: "pointer",
                                                color:"white"
                                            }}>Изменить</p></label>
                                        </div>
                                        :
                                        <label htmlFor={"logo-input"}>
                                            <div style={{
                                                width: "100%",
                                                height: 180,
                                                background: "#2A2A2C",
                                                borderRadius: 12,
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center"
                                            }}>
                                                <AddIcon/>
                                            </div>
                                        </label>
                                }
                                <input accept={"image"} id={"logo-input"} type={"file"}
                                       style={{display: "none"}}
                                       onChange={(event) => {
                                           setLogo(event.target.files[0]);
                                           if(event.target.files[0]){
                                               setLogoPreview(URL.createObjectURL(event.target.files[0]));
                                           }
                                       }}
                                />
                            </div>
                            <Input
                                type={"text"}
                                label="Заголовок"
                                value={articleData.title}
                                size={"sm"}
                                radius="lg"
                                style={{color: "white"}}
                                className={"input-text-color"}
                                classNames={{
                                    label: "input-text-color",
                                    input: "text-white/90",
                                    innerWrapper: "input-text-color",
                                    inputWrapper: "input-main",
                                }}
                                onChange={(event) => {
                                    setArticleData({...articleData, title: event.target.value})
                                }}
                            />
                            <Select
                                placeholder={"Выбрать город"}
                                defaultSelectedKeys={articleData.city ? [articleData.city] : null}
                                onChange={(items) => {
                                    // setCity(items.target.value);
                                    setArticleData({...articleData, city: items.target.value});
                                    if (!items.target.value ||  items.target.value === "0") {
                                        // setCity(null);
                                    }
                                }}
                                listboxProps={{
                                    itemClasses: {
                                        base: "wrapper-content"
                                    },
                                }}
                                popoverProps={{
                                    classNames: {
                                        content: "wrapper",
                                    },
                                }}
                                disallowEmptySelection={false}
                                color={"secondary"}
                                size={"sm"}
                                style={{borderRadius: 16, background: "#2A2A2D", color: "white"}}
                                classNames={{
                                    label: "text-white",
                                }}
                            >
                                {cities.map((city) => (
                                    <SelectItem key={city.id} value={city.name}>
                                        {city.name}
                                    </SelectItem>
                                ))}
                            </Select>
                            <Textarea
                                type={"text"}
                                label="Текст новости"
                                value={articleData.text}
                                size={"sm"}
                                radius="lg"
                                style={{color: "white"}}
                                className={"input-text-color"}
                                classNames={{
                                    label: "input-text-color",
                                    input: "text-white/90",
                                    innerWrapper: "input-text-color",
                                    inputWrapper: "input-main",
                                }}
                                onChange={(event) => {
                                    setArticleData({...articleData, text: event.target.value})
                                }}
                            />
                            <Button style={{
                                background: "#BF0",
                                color: "black",
                                fontSize: 16,
                                fontWeight: 600,
                                marginBottom:20
                            }}
                                    onClick={submitArticle}
                            >
                                <p>Добавить</p>
                            </Button>
                        </ModalBody>
                    </ModalContent>
                </Modal>
            </div>
        </>
    )
}

export default News;
