import {
    Button,
    Input,
    Table,
    TableHeader,
    TableColumn,
    TableBody,
    TableRow,
    TableCell,
    ModalContent, ModalHeader, ModalBody, Modal, Spinner
} from "@nextui-org/react";
import {Select, SelectItem} from "@nextui-org/react";
import React, {useEffect, useState} from "react";
import axios from "axios";
import {CloseIcon} from "../../assets/icons/CloseIcon";

const ReferralPlace = () => {
    const [sendData, setSendData] = useState({
        "ref_sum": "",
        "phone_number": ""
    })
    const [error, setError] = useState("");
    const [refFillModal, setRefFillModal] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [result, setResult] = useState({});
    const [time, setTime] = useState("месяц")
    const filterTime = [
        {"id": "месяц", "name": "За месяц"},
        {"id": "неделя", "name": "За неделю"},
        {"id": "день", "name": "За день"}
    ]
    useEffect(() => {
        let url = "https://backend.sorpa.com/users/ref/history/places/"
        if(time){
            url = `https://backend.sorpa.com/users/ref/history/places/?range=${time}`
        }
       axios.get(url).then(res => {
           setResult(res.data);
           setIsLoading(false);
       }).catch(err => {console.log(err)})
    }, [time])

    const submitData = () => {
        axios.post("https://backend.sorpa.com/users/ref/fill/user/", {...sendData}).then(
            res => {
                window.location.reload()
            }
        ).catch(
            err => {
                setError(err.response.data.message);
            }
        )
    }

    if(isLoading){
        return(
            <div className={"overflow-auto p-[16px]"}>
                <div className={"w-full max-w-[900px] h-full p-[16px] bg-[#121212] rounded-md flex flex-col gap-[24px]"}>
                    <Spinner color={"primary"}/>
                </div>
            </div>
        )
    }

    return(
        <div className={"overflow-auto p-[16px]"}>
            <div className={"w-full max-w-[900px] h-full p-[16px] bg-[#121212] rounded-md flex flex-col gap-[24px]"}>
                <h1 className={"text-white text-[24px] font-bold"}>{result.ref_sum.toLocaleString("RU-ru")} бонусных баллов</h1>
                <Button
                    className={"mb-[20px] bg-[#BF0] text-[black] text-[16px] font-bold w-[200px] rounded-2xl"}
                    onClick={() => {setRefFillModal(true)}}
                >
                    <p>Вывести</p>
                </Button>
                <div className={"flex flex-row gap-[24px] items-center mt-[-24px]"}>
                    <h2 className={"text-white font-bold"}>
                        История пополнении
                    </h2>
                    <div className={"w-[200px]"}>
                        <Select
                            placeholder={"Выбрать период"}
                            defaultSelectedKeys={[time]}
                            onChange={(items) => {
                                setTime(items.target.value);
                            }}
                            listboxProps={{
                                itemClasses: {
                                    base: "wrapper-content"
                                },
                            }}
                            popoverProps={{
                                classNames: {
                                    content: "wrapper",
                                },
                            }}
                            disallowEmptySelection={true}
                            color={"secondary"}
                            size={"sm"}
                            style={{borderRadius: 16, background: "#2A2A2D", color: "white"}}
                            classNames={{
                                label: "text-white",
                            }}
                        >
                            {filterTime.map((item) => (
                                <SelectItem key={item.id} value={item.name}>
                                    {item.name}
                                </SelectItem>
                            ))}
                        </Select>
                    </div>
                </div>
                <div>
                    <Table
                        isStriped isHeaderSticky
                        classNames={{
                            base: 'table-wrapper',
                            table: "table",
                            wrapper: "base-wrapper max-w-[1500px]"
                        }}
                        className={"h-full"}
                        aria-label="Example table with infinite pagination"
                    >
                        <TableHeader>
                            <TableColumn style={{
                                background: "#525255",
                                color: "white",
                                fontWeight: "bold"
                            }}>Тип записи</TableColumn>
                            <TableColumn style={{
                                background: "#525255",
                                color: "white",
                                fontWeight: "bold"
                            }}>Сумма</TableColumn>
                            <TableColumn style={{
                                background: "#525255",
                                color: "white",
                                fontWeight: "bold"
                            }}>Пользователь</TableColumn>
                            <TableColumn style={{
                                background: "#525255",
                                color: "white",
                                fontWeight: "bold"
                            }}>Дата</TableColumn>
                        </TableHeader>
                        <TableBody>
                            {result?.items?.map((item, index) => (
                                <TableRow key={index}>
                                    <TableCell><p style={{
                                        borderRight: "1px solid rgba(84, 84, 88, 0.65)",
                                        margin: 0
                                    }}>{item.history_type}</p></TableCell>
                                    <TableCell><p style={{
                                        borderRight: "1px solid rgba(84, 84, 88, 0.65)",
                                        margin: 0
                                    }}>{item.user === "-" ? item.sum.toLocaleString("RU-ru") : `- ${ item.sum.toLocaleString("RU-ru")}`}</p></TableCell>
                                    <TableCell><p style={{
                                        borderRight: "1px solid rgba(84, 84, 88, 0.65)",
                                        margin: 0
                                    }}>{item.user}</p></TableCell>
                                    <TableCell><p style={{
                                        margin: 0
                                    }}>{item.created_at}</p></TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </div>
            </div>
            <Modal isOpen={refFillModal} style={{background: "#121212"}} hideCloseButton={true}>
                <ModalContent>
                    <ModalHeader style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        paddingBottom: 0,
                        paddingRight: 16
                    }}>
                        <h2 style={{color: "white"}}>Вывод бонусных баллов</h2>
                        <Button onClick={() => {
                            setRefFillModal(false)
                        }} isIconOnly={true} style={{background: "none", padding: 0}}><CloseIcon/></Button>
                    </ModalHeader>
                    <ModalBody>
                        {
                            error && <p style={{color: "red"}}>{error}</p>
                        }
                        <Input
                            type={"text"}
                            label={"Введите номер телефона"}
                            size={"sm"}
                            radius="lg"
                            style={{color: "white"}}
                            className={"input-text-color"}
                            classNames={{
                                label: "input-text-color",
                                input: "text-white/90",
                                innerWrapper: "input-text-color",
                                inputWrapper: "input-main",
                            }}
                            onChange={(event) => {
                                setSendData({...sendData, phone_number: event.target.value});
                                setError("");
                            }}
                        />
                        <Input
                            type={"number"}
                            label={"Сумма"}
                            size={"sm"}
                            radius="lg"
                            style={{color: "white"}}
                            className={"input-text-color"}
                            classNames={{
                                label: "input-text-color",
                                input: "text-white/90",
                                innerWrapper: "input-text-color",
                                inputWrapper: "input-main",
                            }}
                            onChange={(event) => {
                                setSendData({...sendData, ref_sum: event.target.value});
                                setError("");
                            }}
                        />
                        <Button style={{
                            background: "#BF0",
                            color: "black",
                            fontSize: 16,
                            fontWeight: 600
                        }}
                                onClick={submitData}
                        >
                            <p>Вывести</p>
                        </Button>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </div>
    )
}

export default ReferralPlace;
