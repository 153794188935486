import {useEffect, useState} from "react";
import axios from "axios";
import {useParams} from "react-router";
import React from "react";
import {
    Button,
    Divider,
    Input,
    Modal,
    ModalBody,
    ModalContent,
    ModalHeader,
    Spinner,
    Tab,
    Tabs
} from "@nextui-org/react";
import {CloseIcon} from "../../assets/icons/CloseIcon";
import {MoveIcon} from "../../assets/icons/MoveIcon";
import {useSearchParams} from "react-router-dom";

const UserCell = ({ user, is_main, setIsRefMoveModal, setRefMoveID, refMoveID}) => {
    const [highlighted, setHighlighted] = useState(false);
    return (
        <div
            onClick={() => {setRefMoveID(user.user_details.user_id); setHighlighted(true)}}
            className={`border-[1px] ${refMoveID === user.user_details.user_id ? "border-[#BBFF00] min-w-full" : "border-[#222222] min-w-[180px]"} min-w-[180px] text-center rounded-md p-[10px] text-white bg-[#222222] font-bold`}
        >
            <div>
                {user.user_details.name}
            </div>
            <div>
                {user.user_details.phone_number}
            </div>
            <div>
                {user.total_invited} в сетке
            </div>
            <div className={"mt-[12px]"}>
                {
                    user.user_details.user_id === refMoveID && user.user_details.user_id !== null &&
                    <Button onClick={() => {setIsRefMoveModal(true)}} className={"w-full bg-black text-white"} size={"md"} startContent={<MoveIcon/>}>Переместить</Button>
                }
            </div>
        </div>
    );
};

const ReferralNet = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [sendData, setSendData] = useState(
        {
            "search": "",
            "ref_type": "place"
        }
    )
    const [error, setError] = useState("");
    const [refMoveID, setRefMoveID] = useState(searchParams.get("search") ? parseInt(searchParams.get("search")) : null);
    const [isRefMoveModal, setIsRefMoveModal] = useState(false);
    const params = useParams();
    const [user, setUser] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        axios.get(`https://backend.sorpa.com/users/ref/net/${params.ref_id}/`).then(
            res=>{
                setUser(res.data);
                setIsLoading(false);
            }
        ).catch(
            err => {console.log(err)}
        )
    }, []);
    const renderTree = (user, is_main = false) => {
        return (
            <div className={"text-center mb-[20px] overflow-auto"}>
                {/* Текущий пользователь */}
                <UserCell user={user} is_main={is_main} setIsRefMoveModal={setIsRefMoveModal} setRefMoveID={setRefMoveID} refMoveID={refMoveID}/>
                {/* Приглашённые пользователи */}
                {user.invited_users.length > 0 && (
                    <div className={"flex justify-start gap-[12px] mt-[12px]"}>
                        {user.invited_users.map((invitedUser, index) => (
                            <div key={index}>
                                {renderTree(invitedUser)}
                            </div>
                        ))}
                    </div>
                )}
            </div>
        );
    };
    const submitMove = () => {
        axios.post(`https://backend.sorpa.com/users/ref/move/${refMoveID}/`, {...sendData}).then(
            res => {
                window.location.reload();
            }
        ).catch(
            err => {
                console.log(err);
                setError(err.response.data.message);
            }
        )
    }
    return (
        <div className={"p-[16px]"}>
            <div className={"max-w-[1100px] p-[16px] overflow-auto max-h-screen bg-[#121212] flex flex-col gap-[24px]"}>
                {
                    isLoading ? <Spinner color={"primary"}/> :
                        <>
                            {
                                Object.keys(user).length > 0 &&
                                    <h1 className={"text-white text-[24px] font-bold m-0"}>{user.user_details.name} • {user.user_details.phone_number}</h1>
                            }
                            <Divider style={{background: "rgba(84, 84, 88, 0.65)"}}/>
                            {Object.keys(user).length > 0 && renderTree(user, true)}
                        </>
                }
            </div>
            <Modal isOpen={isRefMoveModal} style={{background: "#121212"}} hideCloseButton={true}>
                <ModalContent>
                    <ModalHeader style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        paddingBottom: 0,
                        paddingRight: 16
                    }}>
                        <h2 style={{color: "white"}}>Переместить пользователя</h2>
                        <Button onClick={() => {
                            setIsRefMoveModal(false)
                        }} isIconOnly={true} style={{background: "none", padding: 0}}><CloseIcon/></Button>
                    </ModalHeader>
                    <ModalBody>
                        {
                            error && <p style={{color: "red"}}>{error}</p>
                        }
                        <Tabs aria-label="Options"
                              variant={"solid"}
                              color={"white"}
                              classNames={{
                                  tabList: "tablist-back",
                                  cursor: "tablist-tab",
                                  tabContent: "tablist-text group-data-[selected=true]:text-tablist-text"
                              }}
                              onSelectionChange={(tabName) => {
                                  setSendData({...sendData, ref_type: tabName})
                              }}
                        >
                            <Tab key="place" title="В заведение">

                            </Tab>
                            <Tab key="user" title="К пользователю">

                            </Tab>
                        </Tabs>
                        <Input
                            type={"text"}
                            label={sendData.ref_type === "user" ? "Введите номер телефона" : "Введите название заведения"}
                            size={"sm"}
                            radius="lg"
                            style={{color: "white"}}
                            className={"input-text-color"}
                            classNames={{
                                label: "input-text-color",
                                input: "text-white/90",
                                innerWrapper: "input-text-color",
                                inputWrapper: "input-main",
                            }}
                            onChange={(event) => {
                                setSendData({...sendData, search: event.target.value});
                                setError("");
                            }}
                        />
                        <p className={"text-white text-center text-[12px]"}>Обязательно перепроверьте!</p>
                        <Button style={{
                            background: "#BF0",
                            color: "black",
                            fontSize: 16,
                            fontWeight: 600
                        }}
                                onClick={submitMove}
                        >
                            <p>Переместить</p>
                        </Button>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </div>

    );
};

export default ReferralNet;
