import React, {useState} from "react";
import {
    Table,
    TableHeader,
    TableColumn,
    TableBody,
    TableRow,
    TableCell,
    Spinner,
    getKeyValue,
    Input, Button
} from "@nextui-org/react";
import {useInfiniteScroll} from "@nextui-org/use-infinite-scroll";
import {useAsyncList} from "@react-stately/data";
import axios from "axios";
import ExcelSave from "../../components/ExcelSave";

export default function Reports() {
    const [isLoading, setIsLoading] = React.useState(true);
    const [hasMore, setHasMore] = React.useState(false);
    const [searchDetails, setSearchDetails] = useState({
        place: "",
        branch: "",
        date_from: "",
        date_to: ""
    })

    const [contacts, setContacts] = useState({});

    let list = useAsyncList({
        async load({signal, cursor}) {

            if (cursor) {
                setIsLoading(false);
            }

            const res = await axios.get(cursor || url);
            let json = await res.data

            if (json?.name){
                setContacts(
                    {
                        "contact": json.contacts,
                        "agreement": json.agreement,
                        "name": json.name,
                        "downloadURL": json.download,
                    }
                )
            }

            setHasMore(json?.next !== null);

            const decodedURL = decodeURIComponent(json?.next);

            if (Array.isArray(json.results)){
                return {
                    items: json?.results,
                    cursor: decodedURL,
                };
            }else{
                return {
                    items: [],
                    cursor: null,
                };
            }
        },
    });

    const [loaderRef, scrollerRef] = useInfiniteScroll({hasMore, onLoadMore: list.loadMore});

    let url = "https://backend.sorpa.com/pay/place/report/";

    const submitSearch = () => {
        const temp_search = {}
        if (searchDetails.branch !== ""){
            temp_search['branch'] = searchDetails.branch
        }
        if (searchDetails.place !== ""){
            temp_search['place'] = searchDetails.place
        }
        if (searchDetails.date_from !== ""){
            temp_search['date_from'] = searchDetails.date_from
        }
        if (searchDetails.date_to !== ""){
            temp_search['date_to'] = searchDetails.date_to
        }
        const queryString = new URLSearchParams(temp_search).toString();
        url = `https://backend.sorpa.com/pay/place/report/?${queryString}`
        list.reload();
    }

    const clearSearch = () => {
        window.location.reload();
    }

    return (
        <div className={"p-[12px] min-h-[500px] flex flex-col gap-[24px]"}>
            <h2 className={"text-white font-bold text-[24px]"}>Отчеты</h2>
            <div style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                gap: 4,
                alignItems: "center"
            }}>
                <div className={"flex gap-[12px]"}>
                    {
                        localStorage.getItem("role") === "Администратор" &&
                        <Input
                            type={"email"}
                            size={"sm"}
                            radius="lg"
                            style={{color: "white"}}
                            className={"input-text-color"}
                            classNames={{
                                label: "input-text-color",
                                input: "text-white/90",
                                innerWrapper: "input-text-color",
                                inputWrapper: "input-main",
                            }}
                            value={searchDetails.place}
                            placeholder={"Название предприятия"}
                            onChange={(event) => {
                                setSearchDetails({...searchDetails, place: event.target.value});
                            }}
                        />
                    }
                    <Input
                        type={"email"}
                        size={"sm"}
                        radius="lg"
                        style={{color: "white"}}
                        className={"input-text-color"}
                        classNames={{
                            label: "input-text-color",
                            input: "text-white/90",
                            innerWrapper: "input-text-color",
                            inputWrapper: "input-main",
                        }}
                        value={searchDetails.branch}
                        placeholder={"Название филиала"}
                        onChange={(event) => {
                            setSearchDetails({...searchDetails, branch: event.target.value});
                        }}
                    />
                    <Input
                        type={"date"}
                        size={"sm"}
                        radius="lg"
                        style={{color: "white"}}
                        className={"input-text-color"}
                        classNames={{
                            label: "input-text-color",
                            input: "text-white/90",
                            innerWrapper: "input-text-color",
                            inputWrapper: "input-main",
                        }}
                        value={searchDetails.date_from}
                        placeholder={"Название филиала"}
                        onChange={(event) => {
                            setSearchDetails({...searchDetails, date_from: event.target.value});
                        }}
                    />
                    <Input
                        type={"date"}
                        size={"sm"}
                        radius="lg"
                        style={{color: "white"}}
                        className={"input-text-color"}
                        classNames={{
                            label: "input-text-color",
                            input: "text-white/90",
                            innerWrapper: "input-text-color",
                            inputWrapper: "input-main",
                        }}
                        value={searchDetails.date_to}
                        placeholder={"Название филиала"}
                        onChange={(event) => {
                            setSearchDetails({...searchDetails, date_to: event.target.value});
                        }}
                    />
                </div>
                <Button style={{
                    height: "44px",
                    background: "#BF0",
                    color: "black",
                    fontWeight: 600
                }}
                        onClick={() => {submitSearch()}}
                >
                    <p>Найти</p>
                </Button>
                {
                    searchDetails.place !== "" || searchDetails.branch !== "" || searchDetails.date_from !== "" || searchDetails.date_to !== "" ?  <Button className={"text-white bg-transparent"}
                                  onClick={clearSearch}
                    >
                        <p>Сбросить фильтры</p>
                    </Button> : null
                }
            </div>
            {
                Object.keys(contacts).length > 0 &&
                <div className={"flex text-white gap-[24px] bg-[#222222] p-[12px] rounded-md w-full"}>
                    <div className={"h-full"}>
                        <p className={"font-bold"}>Наименование</p>
                        <p className={"pt-[12px] max-w-[300px]"}>{contacts.name}</p>
                    </div>
                    <div className={"h-full"}>
                        <p className={"font-bold pb-[12px]"}>Договор</p>
                        <a download={true} href={contacts.agreement}><Button>Скачать</Button></a>
                    </div>
                    <div className={"h-full"}>
                        <p className={"font-bold"}>Контактное лицо</p>
                        <p className={"pt-[12px] max-w-[300px]"}>{contacts.contact}</p>
                    </div>
                    <div>
                        <ExcelSave url={contacts.downloadURL} name={`${searchDetails.place} ${searchDetails.branch}`}/>
                    </div>
                </div>
            }

            <Table
                isStriped isHeaderSticky
                classNames={{
                    base: 'table-wrapper',
                    table: "table",
                    wrapper: "base-wrapper max-w-[1500px]"
                }}
                className={"h-full"}
                aria-label="Example table with infinite pagination"
                baseRef={scrollerRef}
                bottomContent={
                    hasMore ? (
                        <div ref={loaderRef} className="flex w-full justify-center">
                            {/*<Spinner  color="transparent" className={"bg-transparent text-transparent"} />*/}
                        </div>
                    ) : null
                }
            >
                <TableHeader>
                    <TableColumn key="index" style={{background: "#525255", color: "white", fontWeight: "bold"}}>№</TableColumn>
                    <TableColumn key="created_at" style={{background: "#525255", color: "white", fontWeight: "bold", minWidth:150}}>Дата</TableColumn>
                    <TableColumn key="id" style={{background: "#525255", color: "white", fontWeight: "bold"}}>Идентификационный <br/> номер операции</TableColumn>
                    <TableColumn key="username" style={{background: "#525255", color: "white", fontWeight: "bold", maxWidth:250, width:250}}>Наименование отправителя или <br/> номер телефона или БИН/ИИН</TableColumn>
                    <TableColumn key="branch_name" style={{background: "#525255", color: "white", fontWeight: "bold", maxWidth:150}}>Филиал предприятия</TableColumn>
                    <TableColumn key="status" style={{background: "#525255", color: "white", fontWeight: "bold"}}>Статус</TableColumn>
                    <TableColumn key="sum" style={{background: "#525255", color: "white", fontWeight: "bold"}}>Сумма операции</TableColumn>
                    <TableColumn key="branch_percent" style={{background: "#525255", color: "white", fontWeight: "bold"}}>Комиссия в %</TableColumn>
                    <TableColumn key="commission" style={{background: "#525255", color: "white", fontWeight: "bold"}}>Сумма комиссии</TableColumn>
                    <TableColumn key="partner_sum" style={{background: "#525255", color: "white", fontWeight: "bold"}}>Сумма партнера</TableColumn>
                    <TableColumn key="return_sum" style={{background: "#525255", color: "white", fontWeight: "bold"}}>Сумма возврата</TableColumn>
                    <TableColumn key="bonus_pay" style={{background: "#525255", color: "white", fontWeight: "bold"}}>Сумма <br/> Оплаченная добавкой</TableColumn>
                    <TableColumn key="balance_add_pay" style={{background: "#525255", color: "white", fontWeight: "bold"}}>Сумма <br/> С бонусного счета</TableColumn>
                    <TableColumn key="balance_pay" style={{background: "#525255", color: "white", fontWeight: "bold"}}>Сумма <br/> Оплаченная со счета</TableColumn>
                </TableHeader>
                <TableBody
                    isLoading={isLoading}
                    items={list.items}
                    // loadingContent={<Spinner color="transparent" className={"bg-transparent -transparent"}/>}
                >
                    {(item) => (
                        <TableRow key={item.name}>
                            {(columnKey) => <TableCell>{columnKey && getKeyValue(item, columnKey)}</TableCell>}
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        </div>
    );
}
